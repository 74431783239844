export default {
  loading: true,
  error: null,
  data: [
    {
      id: 0,
      name: '---',
      attachment: 'test.jpg',
      image: 'https://lorempixel.com/600/600/',
      created_at: '2019-04-23 02:21:02',
      updated_at: '2019-04-23 02:21:02',
    },
  ],
};
